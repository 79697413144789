import React from 'react';
import {
  Redirect,
  Link
} from "react-router-dom";

export class SendPassword extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      redirect: null,
      error: null,
      message: null,
      email: ("location" in this.props
      && "state" in this.props.location
      && typeof this.props.location.state === "object"
      && "email" in this.props.location.state)
        ? this.props.location.state.email : ""
    }
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  handleSubmit(event) {
    // Prevent native form submit
    event.preventDefault();

    // Prepare data to be submitted
    let data = { email: this.state.email };

    // Validate data (in case `reqired` inputs fail)
    if(data.email === "") {
      this.setState({ error: "Missing email, please try again" });
      return;
    }

    // Reset error message
    this.setState({ error: null });

    // Submit credentials to API
    fetch("https://api.lotsuite.com/v1/auth/send_password", {
      method: 'POST',
      body: JSON.stringify(data)
    }).then((response) => {
        // Check for errors (HTTP status code outside of 200-299)
        if(!response.ok) {
          // Show invalid email message
          this.setState({ error: "Invalid email, please try again" });
          return false;
        }

        // Parse stream into JSON
        return response.json();
      })
      .then((data) => {
        // Check for request body (undefined if error before parsing JSON)
        if(!data) return;

        // Indicate that password email has been sent
        this.setState({ message: "A link to set a new password has been sent to "+this.state.email });
      })
      .catch((error) => {
        // Log error
        console.error('Error:', error);
        // Show error message
        this.setState({ error: "Network error, please try again" });
      });
  }

  handleChange(event) {
    // Reset error message
    let new_state = { error: null };
    // Handle normal input values
    new_state[event.target.name] = event.target.value;
    // Update state
    this.setState(new_state);
  }

  render() {
    // Redirect
    if(this.state.redirect) return <Redirect to={this.state.redirect}/>

    // Create error message
    const errorMessage = (!this.state.error) ? null : (
      <div className="error">
        {this.state.error}
      </div>
    );

    // Create status message
    const statusMessage = (!this.state.message) ? null : (
      <div className="message">
        {this.state.message}
      </div>
    );

    return (
      <div className="SendPassword">
        <h2>Send Password</h2>

        {(this.state.message) ? null : (
          <p>
            Send yourself an email with a link to set a new password
          </p>
        )}

        <form onSubmit={this.handleSubmit}>
          {(this.state.message) ? null : (
            <label>
              Email
              <input
                required
                type="email"
                name="email"
                autoComplete="email"
                value={this.state.email}
                onChange={this.handleChange}
                placeholder="you@yourcompany.com"/>
            </label>
          )}

          {errorMessage}

          {statusMessage}

          {(this.state.message) ? null : (
            <button>
              Submit
            </button>
          )}

          <div className="links">

            <a
              target="_blank"
              rel="noopener noreferrer"
              href={"mailto:support@lotsuite.com"}>
              support@lotsuite.com
            </a>

            <Link to={{
              pathname: "/login",
              state: { email: this.state.email }
            }}>
              Back to Login
            </Link>

          </div>

        </form>
      </div>
    )
  }
}

export default SendPassword;
