import React, { useState } from 'react';
import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Switch
} from "react-router-dom";
import AuthPage from './Auth';
import ConsolePage from './Console';
import './App.css';

function App() {
  const [token, setToken] = useState(localStorage.getItem("token"));

  const Auth = {
    // Auth variables
    token: token,
    isAuthenticated: (token !== null) ? true : false,
    // Update token in local storage each time token in state changes
    saveTokenWhenUpdated: (token !== null)
      ? localStorage.setItem("token", token)
      : localStorage.removeItem("token"),
    // Save auth status
    login: (new_token) => setToken(new_token),
    // Reset auth status
    signout: () => setToken(null)
  };

  const PrivateRoute = function({ children, ...rest }) { return (
    <Route {...rest} render={({ location }) =>
      Auth.isAuthenticated
        ? ( children )
        : ( <Redirect to={{ pathname: "/login" }}/> )
    }/>
  )}

  return (
    <Router>
      <div className="App">
        <Switch>

          <Route path="(/login|/send_password|/set_password)">
            <AuthPage Auth={Auth}/>
          </Route>

          <PrivateRoute exact path='(/|/docs)'>
            <ConsolePage Auth={Auth}/>
          </PrivateRoute>

          <Redirect to="/"/>

        </Switch>

      </div>
    </Router>
  );
}

export default App;
