import React from 'react';

export class Lists extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      loading: true,
      lists: null,
      bookmark: null
    };
  }

  componentDidMount() {
    // Get docs from API
    this.requestLists();
  }

  requestLists(bookmark) {
    // Indicate loading
    this.setState({ loading: true });

    // Get API token
    const token = this.props.Auth.token;

    // Prepare URL
    let url = "https://api.lotsuite.com/v1/lists";
    // Add bookmark
    if(bookmark) url += "?bookmark="+bookmark;

    // Submit request to API
    fetch(url, {
      headers: new Headers({
        "Authorization": `Basic ${window.btoa(`api:${token}`)}`
      })
    }).then((response) => {
        // Check for errors (HTTP status code outside of 200-299)
        if(!response.ok) {
          // Log out (token is invalid)
          this.props.Auth.signout();
          return;
        }

        // Parse stream into JSON
        return response.json();
      })
      .then((data) => {
        // Add lists to existing lists
        let lists = (this.state.lists === null)
          ? data.lists
          : this.state.lists.concat(data.lists);
        // Save docs to state
        this.setState({
          loading: false,
          lists: lists,
          bookmark: ("bookmark" in data) ? data.bookmark : null
        });
      })
      .catch((error) => {
        // Log error
        console.error('Error:', error);
        // Show error message
        this.setState({
          error: "Network error, please try again",
          loading: false
        });
      });
  }

  render() {
    let lists = [];
    if(this.state.lists !== null) {
      for(let i=0; i<this.state.lists.length; i++) {
        lists.push(
          <tr key={i}>
            <td>{this.state.lists[i].name}</td>
            <td>{this.state.lists[i].id}</td>
            <td>True</td>
            <td>Active</td>
          </tr>
        );
      }
    }

    const supportEmail = (
      <a
        target="_blank"
        rel="noopener noreferrer"
        href={"mailto:support@lotsuite.com"}>
        support@lotsuite.com
      </a>
    );

    return (
      <div className="Lists">
        <table>
          <thead>
            <tr>
              <th>List Name</th>
              <th>List ID</th>
              <th>Linked</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>

            {lists}

            {(!this.state.loading && this.state.bookmark) ? (
              <tr className="bookmark">
                <td colSpan="2">
                  <span
                    onClick={() => this.requestLists(this.state.bookmark)}>
                    Load More
                  </span>
                </td>
              </tr>
            ) : null}

            {(this.state.error) ? (
              <tr className="error">
                <td colSpan="4">
                  {this.state.error}
                </td>
              </tr>
            ) : null}

            {(this.state.loading) ? (
              <tr className="loading">
                <td colSpan="4">
                  Loading lists...
                </td>
              </tr>
            ) : null}

            {(!this.state.loading && lists.length === 0) ? (
              <tr className="empty">
                <td colSpan="4">
                  You do not have access to any lists, contact&nbsp;
                  {supportEmail} to get started
                </td>
              </tr>
            ) : null}

          </tbody>
        </table>
      </div>
    )
  }
}

export default Lists;
