import React from 'react';
import {
  Link,
  NavLink
} from "react-router-dom";
import './styles.css';
import { ReactComponent as LotSuiteIcon } from './lotsuite.svg';

class HeaderLinks extends React.Component {
  render() {
    // Hide links if not authenticated (e.g. on login screen)
    if(!this.props.Auth.isAuthenticated) return (
      <ul className="Menu">
        <li>
          <a href={"https://www.lotsuite.com"}>
            Return to Website
          </a>
        </li>
      </ul>
    )

    return (
      <ul className="Menu">
        <li>
          <NavLink exact={true} to="/">
            Overview
          </NavLink>
        </li>
        <li>
          <NavLink exact={true} to="/docs">
            Documentation
          </NavLink>
        </li>
        <li>
          <span onClick={this.props.Auth.signout}>
            Log Out
          </span>
        </li>
      </ul>
    )
  }
}

export class Header extends React.Component {
  render() {
    return (
      <div className="Header">
        <div className="content">

          <Link to="/" className="logo">
            <LotSuiteIcon className="icon"/>
            <span className="brand">
              LotSuite
            </span>
            <span className="title">
              Developer Console
            </span>
          </Link>

          <HeaderLinks {...this.props}/>

        </div>
      </div>
    )
  }
}

export default Header;
