import React from 'react';

class KeyCreate extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      error: null,
      description: ""
    }
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  handleSubmit(event) {
    // Prevent native form submit
    event.preventDefault();

    // Prepare data to be submitted
    let data = { description: this.state.description };

    // Validate data (in case `reqired` inputs fail)
    if(data.description === "") {
      this.setState({ error: "Missing description, please try again" });
      return;
    }

    // Reset error message
    this.setState({ error: null });

    // Get API token
    const token = this.props.Auth.token;

    // Submit credentials to API
    fetch("https://api.lotsuite.com/v1/keys", {
      method: 'POST',
      headers: new Headers({
        "Authorization": `Basic ${window.btoa(`api:${token}`)}`
      }),
      body: JSON.stringify(data)
    }).then((response) => {
        // Check for errors (HTTP status code outside of 200-299)
        if(!response.ok) {
          // Show invalid email message
          this.setState({ error: "Something went wrong, please try again" });
          return false;
        }

        // Parse stream into JSON
        return response.json();
      })
      .then((data) => {
        // Check for request body (undefined if error before parsing JSON)
        if(!data) return;

        // Add new key to list of keys
        this.props.addKey(data);

        // Reset input
        this.setState({ description: "" });
      })
      .catch((error) => {
        // Log error
        console.error('Error:', error);
        // Show error message
        this.setState({ error: "Network error, please try again" });
      });
  }

  handleChange(event) {
    // Reset error message
    let new_state = { error: null };
    // Handle normal input values
    new_state[event.target.name] = event.target.value;
    // Update state
    this.setState(new_state);
  }

  render() {
    return (
      <div className="KeyCreate">
        <form onSubmit={this.handleSubmit}>
          <label>
            Create API Key:
            <input
              required
              type="text"
              name="description"
              autoComplete="off"
              value={this.state.description}
              onChange={this.handleChange}
              placeholder="Description of key"/>
          </label>

          <button>
            Submit
          </button>

          {(this.state.error) ? (
            <div className="error">
              {this.state.error}
            </div>
          ) : null}

        </form>
      </div>
    )
  }
}

export default KeyCreate;
