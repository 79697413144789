import React from 'react';
import {
  Route,
  withRouter
} from "react-router-dom";
import Header from '../Header';
import Overview from './overview';
import Docs from './docs';
import './styles.css';

export class ConsolePage extends React.Component {
  render() {
    return (
      <div className="ConsolePage">
        <Header {...this.props}/>

        <div className="container">

          <Route exact path="/">
            <Overview {...this.props}/>
          </Route>

          <Route exact path="/docs">
            <Docs {...this.props}/>
          </Route>

        </div>

      </div>
    )
  }
}

export default withRouter(ConsolePage);
