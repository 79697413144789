import React from 'react';
import {
  Redirect,
  Link
} from "react-router-dom";

export class Login extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      redirect: null,
      error: null,
      email: ("location" in this.props
      && "state" in this.props.location
      && typeof this.props.location.state === "object"
      && "email" in this.props.location.state)
        ? this.props.location.state.email : "",
      email_verified: false,
      password: "",
      agree: null,
      agree_checked: false
    }
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  handleSubmit(event) {
    // Prevent native form submit
    event.preventDefault();

    // Prepare data to be submitted
    let data = { email: this.state.email };
    // Add additional data if email is already verified
    if(this.state.email_verified) {
      data.password = this.state.password;
      data.agreements = (this.state.agree_checked)
        ? JSON.parse(this.state.agree)
        : [];
    }

    // Validate data (in case `reqired` inputs fail)
    if(data.email === "") {
      this.setState({ error: "Missing email, please try again" });
      return;
    }
    if("password" in data && data.password === "") {
      this.setState({ error: "Missing password, please try again" });
      return;
    }
    if("agreements" in data && data.agreements.length < 1) {
      this.setState({ error: "Must agree to terms" });
      return;
    }

    // Reset error message
    this.setState({ error: null });

    // Submit credentials to API
    fetch("https://api.lotsuite.com/v1/auth", {
      method: 'POST',
      body: JSON.stringify(data)
    }).then((response) => {
        // Check for errors (HTTP status code outside of 200-299)
        if(!response.ok) {
          // Handle error if verifying email
          if(this.state.email_verified === false) {
            // Show invalid email message
            this.setState({ error: "Invalid email, please try again" });
            return false;
          }

          // Handle error if verifying password
          if(this.state.email_verified === true) {
            // Show invalid password message
            this.setState({ error: "Incorrect password, please try again" });
            return false;
          }
        }

        // Parse stream into JSON
        return response.json();
      })
      .then((data) => {
        // Check for request body (undefined if error before parsing JSON)
        if(!data) return;

        // Handle password verification response
        if(this.state.email_verified === true) {
          // Save auth token and redirect to home screen
          this.props.Auth.login(data.token);
          this.setState({ redirect: "/" });
        }

        // Handle email verification response
        if(this.state.email_verified === false) {
          // Check if email has a password yet
          if(data.password === true) {
            // Allow user to input password
            this.setState({ email_verified: true });
          } else {
            // Send password reset email
            this.setState({ redirect: "/send_password" });
          }
        }

      })
      .catch((error) => {
        // Log error
        console.error('Error:', error);
        // Show error message
        this.setState({ error: "Network error, please try again" });
      });
  }

  handleChange(event) {
    // Reset error message
    let new_state = { error: null };
    // Handle checkbox input values
    if(event.target.type === "checkbox") {
      new_state[event.target.name+"_checked"] = event.target.checked;
    }
    // Handle normal input values
    new_state[event.target.name] = event.target.value;
    // Update state
    this.setState(new_state);
  }

  render() {
    // Redirect
    if(this.state.redirect) return <Redirect to={{
      pathname: this.state.redirect,
      state: { email: this.state.email }
    }}/>

    // Create links
    const terms_and_conditions = (
      <a
        target="_blank"
        rel="noopener noreferrer"
        href="https://legal.makinauto.com/lotsuite/terms_and_conditions.pdf">
        Terms & Conditions
      </a>
    );
    const developer_console_agreement = (
      <a
        target="_blank"
        rel="noopener noreferrer"
        href="https://legal.makinauto.com/lotsuite/developer_console_agreement.pdf">
        Developer Console Agreement
      </a>
    );

    // Create error message
    const errorMessage = (!this.state.error) ? null : (
      <div className="error">
        {this.state.error}
      </div>
    );

    return (
      <div className="Login">
        <h2>Log In</h2>
        <form onSubmit={this.handleSubmit}>
          <label>
            Email
            <input
              required
              type="email"
              name="email"
              autoComplete="email"
              value={this.state.email}
              onChange={this.handleChange}
              placeholder="you@yourcompany.com"/>
          </label>

          {(!this.state.email_verified) ? null : [
            <label key="password">
              Password
              <input
                required
                type="password"
                name="password"
                autoComplete="current-password"
                value={this.state.password}
                onChange={this.handleChange}
                placeholder="••••••••"/>
            </label>,

            <label key="checkbox" className="checkbox">
              <input
                required
                type="checkbox"
                name="agree"
                value={JSON.stringify([
                  "terms_and_conditions",
                  "developer_console_agreement"
                ])}
                checked={this.state.agree_checked}
                onChange={this.handleChange}/>
              <span>
                By checking this box, you acknowledge that you have read and
                agree to the {terms_and_conditions} and
                the {developer_console_agreement}
              </span>
            </label>
          ]}

          {errorMessage}

          <button>
            {(this.state.email_verified)
              ? "Submit"
              : "Next"}
          </button>

          <div className="links">

            <a
              target="_blank"
              rel="noopener noreferrer"
              href={"mailto:support@lotsuite.com"}>
              support@lotsuite.com
            </a>

            {(!this.state.email_verified) ? null : (
              <Link to={{
                pathname: "/send_password",
                state: { email: this.state.email }
              }}>
                Forgot Password?
              </Link>
            )}

          </div>

        </form>
      </div>
    )
  }
}

export default Login;
