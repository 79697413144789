import React from 'react';
import { Link } from "react-router-dom";
import Keys from './keys';
import Lists from './lists'
import './overview.css';

class Overview extends React.Component {
  render() {
    const supportEmail = (
      <a
        target="_blank"
        rel="noopener noreferrer"
        href={"mailto:support@lotsuite.com"}>
        support@lotsuite.com
      </a>
    );

    return (
      <div className="Overview">

        <h1>Welcome to LotSuite!</h1>
        <p>
          Thank you for visiting the LotSuite Developer Console. If you
          have any questions, please contact our support team
          at {supportEmail}.
          <br/><br/>
          For instructions on how to interact with
          LotSuite, see the <Link to="/docs">API documentation</Link>. For
          information about your authentication and client access, see
          below.
        </p>

        <section>
          <h2>API Keys</h2>
          <p>
            Use these API keys to access the LotSuite Public API. Refer to
            the <Link to="/docs">API documentation</Link> to learn more.
          </p>
          <Keys {...this.props}/>
        </section>

        <section>
          <h2>Client Access</h2>
          <p>
            Access information in dealership accounts by referencing the
            corresponding List ID. Each tool in each dealership uses a
            different List ID to store information.
          </p>
          <Lists {...this.props}/>
        </section>

        <div className="Footer">
          &copy; Makin Automotive Software, LLC
        </div>

      </div>
    )
  }
}

export default Overview;
