import React from 'react';
import {
  Route,
  withRouter
} from "react-router-dom";
import Header from '../Header';
import Login from './login';
import SendPassword from './send_password';
import SetPassword from './set_password';
import './styles.css';

export class AuthPage extends React.Component {
  render() {
    return (
      <div className="AuthPage">

        <Header {...this.props}/>

        <div className="auth">

          <Route path="/login">
            <Login {...this.props}/>
          </Route>

          <Route path="/send_password">
            <SendPassword {...this.props}/>
          </Route>

          <Route path="/set_password">
            <SetPassword {...this.props}/>
          </Route>

        </div>

        <div className="Footer">
          &copy; Makin Automotive Software, LLC
        </div>

      </div>
    )
  }
}

export default withRouter(AuthPage);
