import React from 'react';
import './docs.css';

class Docs extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      documentation: null,
      endpoints: null
    };
  }

  componentDidMount() {
    // Get docs from API
    this.requestDocs();
  }

  requestDocs() {
    // Get API token
    const token = this.props.Auth.token;

    // Submit request to API
    fetch("https://api.lotsuite.com/v1/docs", {
      headers: new Headers({
        "Authorization": `Basic ${window.btoa(`api:${token}`)}`
      })
    }).then((response) => {
        // Check for errors (HTTP status code outside of 200-299)
        if(!response.ok) {
          // Log out (token is invalid)
          this.props.Auth.signout();
          return;
        }

        // Parse stream into JSON
        return response.json();
      })
      .then((data) => {
        // Save docs to state
        this.setState({
          documentation: data.documentation,
          endpoints: data.endpoints
        });

        // Scroll to an element if hash is in URL
        if("location" in this.props
        && "hash" in this.props.location
        && this.props.location.hash !== '') document
          .getElementById(this.props.location.hash.replace('#', ''))
          .scrollIntoView();
      })
      .catch((error) => {
        // Log error
        console.error('Error:', error);
        // Show error message
        this.setState({ error: "Network error, please try again" });
      });
  }

  createSection(doc) {
    let html = [];
    // Title
    if("title" in doc) html.push(
      <h2 key="title">
        {doc.title}
      </h2>
    );

    // Text
    if("text" in doc) html.push(
      <p key="text"
        dangerouslySetInnerHTML={{ __html: doc.text
          // Replace line breaks
          .replace(/\n/g, "<br/>")
          // Replace backticks with code
          .replace(/`(.+?)`/g, "<code>$1</code>")
        }}/>
    );

    // Parameters
    if("parameters" in doc) {
      let params = Object.keys(doc.parameters);
      let rows = [];
      for(let i=0; i<params.length; i++) {
        let p = doc.parameters[params[i]];
        rows.push(
          <tr key={i}>
            <td>{params[i]}</td>
            <td>{("required" in p && p.required) ? "X" : ""}</td>
            <td>{("text" in p) ? p.text : ""}</td>
          </tr>
        );
      }
      html.push(
        <table key="table">
          <thead>
            <tr>
              <th>Parameter</th>
              <th>Required</th>
              <th>Description</th>
            </tr>
          </thead>
          <tbody>
            {rows}
          </tbody>
        </table>
      );
    }

    // Example
    if("example" in doc) html.push(
      <pre key="example">
        <code>
          {(typeof doc.example === "object")
            ? JSON.stringify(doc.example, null, 2)
            : doc.example}
        </code>
      </pre>
    );

    // Examples
    if("examples" in doc) {
      for(let i=0; i<doc.examples.length; i++) {
        html.push(
          <pre key={"example_"+i}>
            <code>
              {(typeof doc.examples[i] === "object")
                ? JSON.stringify(doc.examples[i], null, 2)
                : doc.examples[i]}
            </code>
          </pre>
        );
      }
    }

    // Return section
    return html;
  }

  render() {
    // Parse docs
    const loading = (this.state.documentation === null) ? true : false;
    let documentation = [], paths = [], resources = [];
    if(!loading) {
      // Add general documentation
      for(let i=0; i<this.state.documentation.length; i++) {
        // Get link
        paths.push([this.state.documentation[i].title]);
        // Add docs
        documentation.push(
          <section key={i}
            id={this.state.documentation[i].title}>
            {this.createSection(this.state.documentation[i])}
          </section>
        );
      }

      // Add resource documentation
      let endpoints = Object.keys(this.state.endpoints);
      for(let i=0; i<endpoints.length; i++) {
        let endpoint = this.state.endpoints[endpoints[i]];
        let methods = Object.keys(endpoint);
        let methodSections = [];
        // Get link
        paths.push([endpoints[i]]);

        // Parse endpoint methods
        for(let j=0; j<methods.length; j++) {
          let method = endpoint[methods[j]];
          // Get link
          paths.push([methods[j], methods[j]+endpoints[i]]);
          // Add method title
          methodSections.push(
            <h3 key={j}
              className={"method "+methods[j]}
              id={methods[j]+endpoints[i]}>
              {methods[j]}
            </h3>
          );

          // Add method documentation
          methodSections.push(this.createSection(method));

          // Add method request
          if("request" in method) {
            // Title
            methodSections.push(
              <h4 key={"request_"+j}>
                Request
              </h4>
            );
            // Content
            methodSections.push(this.createSection(method.request));
          }

          // Add method response
          if("response" in method) {
            // Title
            methodSections.push(
              <h4 key={"response_"+j}>
                Response
              </h4>
            );
            // Content
            methodSections.push(this.createSection(method.response));
          }
        }

        // Add endpoint to docs
        documentation.push(
          <section
            key={"endpoint_"+i}
            id={endpoints[i]}>
            <h2>{endpoints[i]}</h2>
            {methodSections}
          </section>
        );
      }

      // Add documentation links
      for(let i=0; i<paths.length; i++) {
        // Get hash
        let hash = "#"+((paths[i].length > 1)
          ? paths[i][1] : paths[i][0]);
        // Classes
        let typeClass = (paths[i].length > 1)
          ? "method "+paths[i][0]
          : "endpoint";
        let activeClass = (this.props.location.hash === hash)
          ? "active"
          : ""
        // Create link
        resources.push(
          <a key={i}
            className={typeClass+" "+activeClass}
            href={hash}>
            {paths[i][0]}
          </a>
        )
      }
    }

    return (
      <div className="Docs">

        <div className="resources">
          <h2>
            <a href="#Docs">
              Resources
            </a>
          </h2>

          {(this.state.error) ? (
            <div className="error">
              Something went wrong
            </div>
          ) : (resources.length > 0) ? resources
          : (loading) ? (
            <div className="loading">
              Loading resources...
            </div>
          ) : (
            <div className="empty">
              No resources to show
            </div>
          )}
        </div>

        <div className="documentation">
          <h1 id="Docs">
            API Documentation
          </h1>

          {(this.state.error) ? (
            <div className="error">
              {this.state.error}
            </div>
          ) : (documentation.length > 0) ? documentation
          : (loading) ? (
            <div className="loading">
              Loading documentation...
            </div>
          ) : (
            <div className="empty">
              You do not have permission to view any API resources
            </div>
          )}

          <div className="Footer">
            &copy; Makin Automotive Software, LLC
          </div>
        </div>
      </div>
    )
  }
}

export default Docs;
