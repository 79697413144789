import React from 'react';
import KeyCreate from './key_create';

class Keys extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      loading: null,
      keys: null,
      bookmark: null
    };
  }

  componentDidMount() {
    // Get docs from API
    this.requestKeys();
  }

  requestKeys(bookmark) {
    // Indicate loading
    this.setState({ loading: true });

    // Get API token
    const token = this.props.Auth.token;

    // Prepare URL
    let url = "https://api.lotsuite.com/v1/keys";
    // Add bookmark
    if(bookmark) url += "?bookmark="+bookmark;

    // Submit request to API
    fetch(url, {
      headers: new Headers({
        "Authorization": `Basic ${window.btoa(`api:${token}`)}`
      })
    }).then((response) => {
        // Check for errors (HTTP status code outside of 200-299)
        if(!response.ok) {
          // Log out (token is invalid)
          this.props.Auth.signout();
          return;
        }

        // Parse stream into JSON
        return response.json();
      })
      .then((data) => {
        // Add keys to existing keys
        let keys = (this.state.keys === null)
          ? data.keys
          : this.state.keys.concat(data.keys);
        // Save docs to state
        this.setState({
          loading: false,
          keys: keys,
          bookmark: ("bookmark" in data) ? data.bookmark : null
        });
      })
      .catch((error) => {
        // Log error
        console.error('Error:', error);
        // Show error message
        this.setState({
          error: "Network error, please try again",
          loading: false
        });
      });
  }

  timeDifference(previous) {
    var msPerMinute = 60 * 1000;
    var msPerHour = msPerMinute * 60;
    var msPerDay = msPerHour * 24;
    var msPerMonth = msPerDay * 30;
    var msPerYear = msPerDay * 365;
    var elapsed = new Date() - (previous * 1000);
    // Now
    if(elapsed < 10*1000) {
      return 'Seconds ago';
    }
    // Seconds
    if(elapsed < msPerMinute) {
      let s = (elapsed/1000 >= 1.5) ? "s" : "";
      return Math.round(elapsed/1000) + ' second'+s+' ago';
    }
    // Minutes
    else if(elapsed < msPerHour) {
      let s = (elapsed/msPerMinute >= 1.5) ? "s" : "";
      return Math.round(elapsed/msPerMinute) + ' minute'+s+' ago';
    }
    // Hours
    else if(elapsed < msPerDay ) {
      let s = (elapsed/msPerHour >= 1.5) ? "s" : "";
      return Math.round(elapsed/msPerHour) + ' hour'+s+' ago';
    }
    // Days
    else if(elapsed < msPerMonth) {
      let s = (elapsed/msPerDay >= 1.5) ? "s" : "";
      return Math.round(elapsed/msPerDay) + ' day'+s+' ago';
    }
    // Months
    else if(elapsed < msPerYear) {
      let s = (elapsed/msPerMonth >= 1.5) ? "s" : "";
      return Math.round(elapsed/msPerMonth) + ' month'+s+' ago';
    }
    // Years
    else {
      let s = (elapsed/msPerYear >= 1.5) ? "s" : "";
      return Math.round(elapsed/msPerYear) + ' year'+s+' ago';
    }
  }

  addKey(key) {
    // Add key to existing keys
    let keys = (this.state.keys === null) ? [] : this.state.keys;
    keys.push(key);
    // Update keys in state
    this.setState({ keys: keys });
  }

  render() {
    let keys = [];
    if(this.state.keys !== null) {
      for(let i=0; i<this.state.keys.length; i++) {
        keys.push(
          <tr key={i}>
            <td>{this.state.keys[i].description}</td>
            <td>{this.state.keys[i].key}</td>
            <td>{this.timeDifference(this.state.keys[i].created_at)}</td>
            <td>{(this.state.keys[i].active !== false)
              ? "Active" : "Inactive"}</td>
          </tr>
        );
      }
    }

    return (
      <div className="Keys">

        <table>
          <thead>
            <tr>
              <th>Description</th>
              <th>API Key</th>
              <th>Created</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>

            {keys}

            {(!this.state.loading && this.state.bookmark) ? (
              <tr className="bookmark">
                <td colSpan="4">
                  <span
                    onClick={() => this.requestKeys(this.state.bookmark)}>
                    Load More
                  </span>
                </td>
              </tr>
            ) : null}

            {(this.state.error) ? (
              <tr className="error">
                <td colSpan="4">
                  {this.state.error}
                </td>
              </tr>
            ) : null}

            {(this.state.loading) ? (
              <tr className="loading">
                <td colSpan="4">
                  Loading keys...
                </td>
              </tr>
            ) : null}

            {(!this.state.loading && keys.length === 0) ? (
              <tr className="empty">
                <td colSpan="4">
                  You do not have any API keys yet, create a key to get
                  started
                </td>
              </tr>
            ) : null}

          </tbody>
        </table>

        <KeyCreate {...this.props} addKey={this.addKey.bind(this)}/>

      </div>
    )
  }
}

export default Keys;
