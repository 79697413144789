import React from 'react';
import {
  Redirect,
  Link
} from "react-router-dom";
const jwt = require("jsonwebtoken");

export class SetPassword extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      redirect: null,
      error: null,
      password: "",
      password_confirm: "",
      agree: null,
      agree_checked: false
    }
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  handleSubmit(event) {
    // Prevent native form submit
    event.preventDefault();

    // Prepare data to be submitted
    let data = {
      password: this.state.password,
      token: this.getTokenFromUrl(),
      agreements: (this.state.agree_checked)
        ? JSON.parse(this.state.agree)
        : []
    };

    // Validate data (in case `reqired` inputs fail)
    if(data.password === "") {
      this.setState({ error: "Missing password, please try again" });
      return;
    }
    if(data.password !== this.state.password_confirm) {
      this.setState({ error: "Passwords do not match, please try again" });
      return;
    }
    if(data.token === "") {
      this.setState({ error: "Missing token, please try again" });
      return;
    }
    if(data.agreements.length < 1) {
      this.setState({ error: "Must agree to terms" });
      return;
    }

    // Reset error message
    this.setState({ error: null });

    // Submit credentials to API
    fetch("https://api.lotsuite.com/v1/auth/set_password", {
      method: 'POST',
      body: JSON.stringify(data)
    }).then((response) => {
        // Check for errors (HTTP status code outside of 200-299)
        if(!response.ok) {
          // Show error message
          this.setState({ error: "Something went wrong, please try again" });
          return false;
        }

        // Parse stream into JSON
        return response.json();
      })
      .then((data) => {
        // Check for request body (undefined if error before parsing JSON)
        if(!data) return;

        // Save auth token and redirect to home screen
        this.props.Auth.login(data.token);
        this.setState({ redirect: "/" });
      })
      .catch((error) => {
        // Log error
        console.error('Error:', error);
        // Show error message
        this.setState({ error: "Network error, please try again" });
      });
  }

  handleChange(event) {
    // Reset error message
    let new_state = { error: null };
    // Handle checkbox input values
    if(event.target.type === "checkbox") {
      new_state[event.target.name+"_checked"] = event.target.checked;
    }
    // Handle normal input values
    new_state[event.target.name] = event.target.value;
    // Update state
    this.setState(new_state);
  }

  getTokenFromUrl() {
    // Get query string
    let queryString = ("location" in this.props
    && "search" in this.props.location)
      ? this.props.location.search
      : "";

    // Get password reset token from query string
    let match = queryString.match(/[?|&]prt=([^&]+)/);
    let password_reset_token = (match !== null) ? match[1] : null;

    // Validate token
    try {
      // Decode token
      let decoded = jwt.decode(password_reset_token);
      // Disable token if invalid or over 1 hour old
      if(Math.round(Date.now() / 1000) > (decoded.iat + (60*60))) {
        password_reset_token = null
      }
    }
    catch (err) { password_reset_token = null }

    // Return password reset token
    return password_reset_token;
  }

  render() {
    // Redirect
    if(this.state.redirect) return <Redirect to={this.state.redirect}/>

    // Create links
    const terms_and_conditions = (
      <a
        target="_blank"
        rel="noopener noreferrer"
        href="https://legal.makinauto.com/lotsuite/terms_and_conditions.pdf">
        Terms & Conditions
      </a>
    );
    const developer_console_agreement = (
      <a
        target="_blank"
        rel="noopener noreferrer"
        href="https://legal.makinauto.com/lotsuite/developer_console_agreement.pdf">
        Developer Console Agreement
      </a>
    );

    // Create error message
    const errorMessage = (!this.state.error) ? null : (
      <div className="error">
        {this.state.error}
      </div>
    );

    // Create links element
    const links = (
      <div className="links">

        <a
          target="_blank"
          rel="noopener noreferrer"
          href={"mailto:support@lotsuite.com"}>
          support@lotsuite.com
        </a>

        <Link to={{ pathname: "/login" }}>
          Back to Login
        </Link>

      </div>
    );

    return (
      <div className="SetPassword">
        <h2>Set Password</h2>
        {(this.getTokenFromUrl() === null) ? [
          <p key="p">
            Looks like this link has expired, please check the link or
            try again
          </p>,
          <form key="form">
            {links}
          </form>
        ] : (
          <form onSubmit={this.handleSubmit}>

            <label>
              New Password
              <input
                required
                type="password"
                name="password"
                autoComplete="new-password"
                value={this.state.password}
                onChange={this.handleChange}
                placeholder="••••••••"/>
            </label>

            <label>
              Confirm New Password
              <input
                required
                type="password"
                name="password_confirm"
                autoComplete="new-password"
                value={this.state.password_confirm}
                onChange={this.handleChange}
                placeholder="••••••••"/>
            </label>

            <label className="checkbox">
              <input
                required
                type="checkbox"
                name="agree"
                value={JSON.stringify([
                  "terms_and_conditions",
                  "developer_console_agreement"
                ])}
                checked={this.state.agree_checked}
                onChange={this.handleChange}/>
              <span>
                By checking this box, you acknowledge that you have read and
                agree to the {terms_and_conditions} and
                the {developer_console_agreement}
              </span>
            </label>

            {errorMessage}

            <button>
              Submit
            </button>

            {links}

          </form>
        )}
      </div>
    )
  }
}

export default SetPassword;
